@import './views/person_list_directory_override';
@import './views/research_labs_list';
@import './views/books_by_author';
@import "./views/research_lab_team";

// Careers Listing page
// Fellowship Programs Listing page
// Resident Research Day Listing page
.view-careers-list,
.view-fellowship-list,
.view-resident-research-day-list{
    .views-row{
        padding-bottom: 1rem;
        border-bottom: 1px solid $wcm-border-gray;        
        margin-bottom: 3rem;
    }
}

// Publications page
.view-biblio-views-lab{
    @include biblio-views();
}

// Related Faculty view for Division and Specialty
.view-related-faculty{
    .view-content{
        .person-list-item{
            margin-bottom: 0;
            margin-top: 2rem;
        }
    }
}

// Divisions Landing page
.view-divisions-list{
    @include specialty-callout-view();
}

// Specialties on Divisions
.view-specialties-by-division{
    @include specialty-callout-view();
}

// News Posts on homepage
.view-news-posts{
    .view-content{
        .views-row{
            margin-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid $wcm-border-gray;
            .views-field-field-featured-image{
                img{
                    width: 100%;
                }
                @include breakpoint($sm){
                    margin-left: 2rem;
                    margin-top: 2rem;
                    float: right;
                }
            }
            .views-field-created{
                text-transform: uppercase;
                margin-bottom: 2rem;
            }
            .views-field-body{
                overflow: hidden;
            }
        }
    }
}