.view-research-lab-team{
    .view-header{
        h2{
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
    }
    .view-content{
        overflow: hidden;
        width: 100%;

        .views-row{
            @include breakpoint($md){
                float: left;
                width: 50%;
            }
            @include breakpoint($lg){
                width: 33%;
            }
            margin-bottom: 1.25rem;
        }
    }
}