/* IMPORTANT!
This file should only contain self-contained STYLE code, not GRID LAYOUT.
Please do not use Bootstrap inside paragraphs! If you must, then keep them self
contained inside of a container-fluid element.
Only do grid layout in _pages or _panels, or on fieldsets and fieldable_panel_panes
when necessary if possible.
*/

@import './paragraphs/callout_link_fields';

.paragraphs-items-field-lab-grants{
    .paragraphs-item-grant-fields{
        border-bottom: 1px solid $wcm-border-gray;
    }
}

//.wcm-pgraph-side-callout--image-left{
//    .wcm-pgraph-side-callout__text{
//        background-color: $wcm-white;
//        &__headline{
//            text-align: left;
//            font-size: $font-size-h2;
//        }
//        &__description{
//            text-align: left;
//        }
//    }
//}
