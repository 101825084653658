.alpha-pager{
    padding-left: 0;

    .alpha-wrap{
        display: inline-block;
        padding-right: .75rem;
        padding-left: .25rem;
        border-right: 1px solid $wcm-border-gray;
    }
}

.view-person-list-directory-override{
    .view-content{
        h3{
            margin-bottom: 1rem;
            width: 100%;
            padding-left: 2rem;
        }
    }
}