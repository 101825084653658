// Three Contact Callout
.pane-bundle-multiple-contact-callout{
    .fieldable-panels-pane{
        padding: 3rem 4rem;
        background-color: $wcm-bg-gray;
        h2{
            @include make-md-column(12);
            text-align: center;
            background-color: $wcm-white;
            margin-bottom: 1rem;
        }
        
        .field-contact-person{
            @include make-row();
            background-color: $wcm-white;
            padding: 1rem;

            > article{
                @include make-md-column(4);

                .field-wcm-person-first-name,
                .field-wcm-person-last-name{
                    font-weight: bold;
                }

                .field-label{
                    display: inline-block;
                }

            }
        }
    }
}