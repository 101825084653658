// Individual Research Labs
.node-type-research-lab{

    .field-lab-pi{
        .field-label{
            font-family: $font-family-sans-serif;
            text-transform: uppercase;
            color: $wcm-med-gray;
            font-size: 1.2rem;
            letter-spacing: .2rem;
            font-weight: 700;
        }
    }
}