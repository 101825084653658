
// Research Landing page
.page-node-39{

    .pane-bundle-text{
        text-align: center;
    }

    &:not(.panels-ipe-editing){
        .panel-pane{
            
        }
    }

    &.logged-in{
        &.panels-ipe-editing{
            
        }
    }
}
