/**
 * Example Stub for creating WCMC Subthemes
 *
 */
@import url(../../../../../profiles/wcmc/themes/wcm_brand_base/fonts/fontello/css/fontello.css);
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none !important;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/*
 * This file is for mixins ONLY. Work in the file you need them
 * in, then cut and paste them here when final.
 */
.wcm-red-bg div, .page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(1) div, .page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(3) div,
.wcm-red-bg h2,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(1) h2,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(3) h2,
.wcm-red-bg h3,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(1) h3,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(3) h3,
.wcm-red-bg h4,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(1) h4,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(3) h4,
.wcm-red-bg a,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(1) a,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(3) a {
  color: #fff;
  background-color: #b31b1b; }

.wcm-dark-orange-bg div, .node-type-fellowship .field-fellowship-application-cal div, .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link div, .page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(2) div, .paragraphs-item-callout-link-fields div,
.wcm-dark-orange-bg h2,
.node-type-fellowship .field-fellowship-application-cal h2,
.view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link h2,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(2) h2,
.paragraphs-item-callout-link-fields h2,
.wcm-dark-orange-bg h3,
.node-type-fellowship .field-fellowship-application-cal h3,
.view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link h3,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(2) h3,
.paragraphs-item-callout-link-fields h3,
.wcm-dark-orange-bg h4,
.node-type-fellowship .field-fellowship-application-cal h4,
.view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link h4,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(2) h4,
.paragraphs-item-callout-link-fields h4,
.wcm-dark-orange-bg a,
.node-type-fellowship .field-fellowship-application-cal a,
.view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link a,
.page-node-86 .paragraphs-items-field-link-buttons .paragraphs-item-callout-link-fields:nth-child(2) a,
.paragraphs-item-callout-link-fields a {
  color: #fff;
  background-color: #cf4520; }

/**
 * Mixin targeting the Biblio-related content
 */
.ul-inline ul {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0; }
  .ul-inline ul li {
    display: flex; }

/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/*** Directory ***/
.view-person-list:before, .view-related-faculty:before, .view-person-list-directory-override:before, .view-person-list:after, .view-related-faculty:after, .view-person-list-directory-override:after {
  content: " ";
  display: table; }

.view-person-list:after, .view-related-faculty:after, .view-person-list-directory-override:after {
  clear: both; }

.view-person-list .view-content, .view-related-faculty .view-content, .view-person-list-directory-override .view-content {
  margin-left: -20px;
  margin-right: -20px; }
  @media screen and (min-width: 768px) {
    .view-person-list .view-content, .view-related-faculty .view-content, .view-person-list-directory-override .view-content {
      display: flex;
      flex-wrap: wrap; }
      .view-person-list .view-content:before, .view-related-faculty .view-content:before, .view-person-list-directory-override .view-content:before {
        display: block; } }
  .view-person-list .view-content .person-list-item, .view-related-faculty .view-content .person-list-item, .view-person-list-directory-override .view-content .person-list-item {
    margin: 0 0 30px; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item, .view-related-faculty .view-content .person-list-item, .view-person-list-directory-override .view-content .person-list-item {
        display: flex;
        flex-direction: column; } }
    .view-person-list .view-content .person-list-item .person-display-name, .view-related-faculty .view-content .person-list-item .person-display-name, .view-person-list-directory-override .view-content .person-list-item .person-display-name {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 15px; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:before, .view-related-faculty .view-content .person-list-item .person-display-name .person-name-suffix:before, .view-person-list-directory-override .view-content .person-list-item .person-display-name .person-name-suffix:before {
        content: ', ';
        position: relative; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:empty:before, .view-related-faculty .view-content .person-list-item .person-display-name .person-name-suffix:empty:before, .view-person-list-directory-override .view-content .person-list-item .person-display-name .person-name-suffix:empty:before {
        content: ''; }
    .view-person-list .view-content .person-list-item .person-headshot, .view-related-faculty .view-content .person-list-item .person-headshot, .view-person-list-directory-override .view-content .person-list-item .person-headshot {
      margin-bottom: 5px;
      border-top: 8px solid #b31b1b;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot img, .view-related-faculty .view-content .person-list-item .person-headshot img, .view-person-list-directory-override .view-content .person-list-item .person-headshot img {
        width: 100%;
        transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot .view-profile-details, .view-related-faculty .view-content .person-list-item .person-headshot .view-profile-details, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details {
        cursor: pointer; }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn, .view-related-faculty .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn {
          position: absolute;
          background-color: #b31b1b;
          color: #fff;
          bottom: 0;
          right: 0;
          padding: 3px 10px;
          font-size: 15px; }
          .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after, .view-related-faculty .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after {
            content: "\e80a";
            padding-left: 5px;
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            /* opacity: .8; */
            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal;
            text-transform: none;
            /* fix buttons height, for twitter bootstrap */
            line-height: 1em;
            /* Animation center compensation - margins should be symmetric */
            /* remove if not needed */
            /* you can be more comfortable with increased icons size */
            /* font-size: 120%; */
            /* Font smoothing. That was taken from TWBS */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            /* Uncomment for 3D effect */
            /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover img, .view-related-faculty .view-content .person-list-item .person-headshot .view-profile-details:hover img, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details:hover img {
          transform: scale(1.1); }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn, .view-related-faculty .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn {
          background-color: #cf4520; }
    .view-person-list .view-content .person-list-item.is-hover .person-headshot, .view-related-faculty .view-content .person-list-item.is-hover .person-headshot, .view-person-list-directory-override .view-content .person-list-item.is-hover .person-headshot {
      border-top: 8px solid #cf4520; }
      .view-person-list .view-content .person-list-item.is-hover .person-headshot img, .view-related-faculty .view-content .person-list-item.is-hover .person-headshot img, .view-person-list-directory-override .view-content .person-list-item.is-hover .person-headshot img {
        transform: scale(1.1); }
    .view-person-list .view-content .person-list-item.is-hover .view-details-btn, .view-related-faculty .view-content .person-list-item.is-hover .view-details-btn, .view-person-list-directory-override .view-content .person-list-item.is-hover .view-details-btn {
      background-color: #cf4520 !important; }
      .view-person-list .view-content .person-list-item.is-hover .view-details-btn:after, .view-related-faculty .view-content .person-list-item.is-hover .view-details-btn:after, .view-person-list-directory-override .view-content .person-list-item.is-hover .view-details-btn:after {
        transform: rotate(180deg);
        padding-right: 5px;
        padding-left: 0 !important; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item.is-hover:after, .view-related-faculty .view-content .person-list-item.is-hover:after, .view-person-list-directory-override .view-content .person-list-item.is-hover:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #f7f7f7 transparent;
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -20px; } }
    .view-person-list .view-content .person-list-item .person-details-wrapper, .view-related-faculty .view-content .person-list-item .person-details-wrapper, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper {
      display: none; }
      .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details, .view-related-faculty .view-content .person-list-item .person-details-wrapper .field-person-details, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper .field-person-details {
        background-color: #f7f7f7;
        padding: 15px;
        margin-top: 20px;
        position: relative; }
        .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details:before, .view-related-faculty .view-content .person-list-item .person-details-wrapper .field-person-details:before, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper .field-person-details:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 20px 20px 20px;
          border-color: transparent transparent #f7f7f7 transparent;
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -20px; }
    .view-person-list .view-content .person-list-item .person-title ul, .view-related-faculty .view-content .person-list-item .person-title ul, .view-person-list-directory-override .view-content .person-list-item .person-title ul {
      margin: 0;
      padding: 0; }
      .view-person-list .view-content .person-list-item .person-title ul li, .view-related-faculty .view-content .person-list-item .person-title ul li, .view-person-list-directory-override .view-content .person-list-item .person-title ul li {
        list-style-type: none;
        margin: 0;
        margin-bottom: 5px; }

.view-person-list .bio-row-container, .view-related-faculty .bio-row-container, .view-person-list-directory-override .bio-row-container {
  display: none; }
  .view-person-list .bio-row-container .bio-row-content, .view-related-faculty .bio-row-container .bio-row-content, .view-person-list-directory-override .bio-row-container .bio-row-content {
    background-color: #f7f7f7;
    padding: 15px;
    margin: 0px 0px 30px;
    position: relative; }
  .view-person-list .bio-row-container.is-hidden, .view-related-faculty .bio-row-container.is-hidden, .view-person-list-directory-override .bio-row-container.is-hidden {
    display: none; }

.pane-person-list-panel-pane-2 .pane-title {
  margin-bottom: 20px;
  font-size: 30px; }

.pane-person-list-panel-pane-2 .view-person-list .view-content, .pane-person-list-panel-pane-2 .view-related-faculty .view-content, .pane-person-list-panel-pane-2 .view-person-list-directory-override .view-content {
  display: block;
  margin-left: 0;
  margin-right: 0; }

.pane-person-list-panel-pane-2 .view-person-list .person-list-item, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item {
  display: block;
  font-size: inherit; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .person-headshot, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .person-headshot {
      float: left;
      margin-right: 30px; } }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .person-display-name, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .person-display-name {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 20px;
    color: #b31b1b; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-department, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-field-wcm-person-department, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-department {
    margin-bottom: 10px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-research-url, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-field-wcm-person-research-url, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-research-url {
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-project-locations .views-label, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-field-project-locations .views-label, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-project-locations .views-label {
    color: #b31b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-view .view-projects, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-projects {
    margin-top: 10px; }
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects .featured-projects-heading, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-view .view-projects .featured-projects-heading, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-projects .featured-projects-heading {
      color: #b31b1b;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-2 .view-related-faculty .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-content {
    margin-left: 0;
    margin-right: 0; }

.pane-person-list-panel-pane-3 {
  background-color: #555555;
  max-width: 300px;
  margin: 30px auto 0; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-3 {
      max-width: none; } }
  .pane-person-list-panel-pane-3 .view-person-list, .pane-person-list-panel-pane-3 .view-related-faculty, .pane-person-list-panel-pane-3 .view-person-list-directory-override {
    position: relative; }
    .pane-person-list-panel-pane-3 .view-person-list .view-content, .pane-person-list-panel-pane-3 .view-related-faculty .view-content, .pane-person-list-panel-pane-3 .view-person-list-directory-override .view-content {
      display: block;
      margin-left: 0;
      margin-right: 0; }
    .pane-person-list-panel-pane-3 .view-person-list .person-list-item, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item {
      flex-direction: inherit;
      font-size: inherit;
      margin-bottom: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-headshot {
        border: none; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-headshot {
            float: left;
            width: 30%;
            padding-right: 30px;
            margin-bottom: 0; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .person-display-name, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-display-name {
        font-family: "1898Sans-Bold", sans-serif;
        font-size: 20px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-view .view-content {
        margin-left: 0;
        margin-right: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .project-locations div, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .project-locations div, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .project-locations div {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 {
        position: relative;
        padding: 20px; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 {
            padding: 0 20px 0 30px;
            width: 70%;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .person-details, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .person-details, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .person-details {
              position: relative;
              top: 50%;
              -webkit-transform: translateY(-50%);
              /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
              -ms-transform: translateY(-50%);
              /* IE 9 */
              transform: translateY(-50%);
              /* IE 10, Fx 16+, Op 12.1+ */
              position: absolute;
              width: 100%;
              padding-right: 40px; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects {
          margin-top: 20px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects .project-details, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details {
            display: inline;
            margin: 0; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:after, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects .project-details:after, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details:after {
              content: ', ';
              position: relative;
              margin-left: -4px; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after {
              content: ''; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details a, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-1 .view-projects .project-details a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details a {
              color: #ffc72c; }
      @media screen and (min-width: 768px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 {
          position: absolute;
          bottom: 0;
          right: 0; } }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 {
          bottom: -45px; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a {
        position: relative;
        background: #cf4520;
        padding: 10px 40px;
        border: none;
        margin: 5px 0 0;
        display: block;
        text-align: center; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a {
            margin: 0;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before {
              content: '';
              position: absolute;
              right: 0;
              height: 70%;
              top: 15%;
              border: 0.5px solid #fff;
              opacity: 0.5; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:hover, .pane-person-list-panel-pane-3 .view-related-faculty .person-list-item .views-field-nothing-2 a:hover, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a:hover {
          background: #b31b1b; }

.node-type-specialty .pane-node-field-body {
  margin-top: 1rem; }

.node-contact {
  text-align: center; }
  .node-contact .field-contact-details {
    font-weight: bold; }
  .node-contact .group-phone-details {
    margin-top: 1rem; }
    .node-contact .group-phone-details > div {
      display: inline-block; }
      .node-contact .group-phone-details > div:not(:last-child) {
        border-right: 2px solid #dddddd;
        margin-right: 2rem;
        padding-right: 2rem; }
    .node-contact .group-phone-details .field-label {
      display: inline; }

article.node-contact {
  border: 3rem solid #f7f7f7;
  padding: 2rem; }

.node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body {
  margin-left: -20px;
  margin-right: -20px; }
  .node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body:before, .node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body:after {
    content: " ";
    display: table; }
  .node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body:after {
    clear: both; }
  .node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body .panel-pane,
  .node-type-panopoly-page:not(.panels-ipe-editing) .main-content__body .ctools-collapsible-container {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }

.node-type-fellowship .field-deadline-information {
  border: 2rem solid #f7f7f7;
  padding: 1rem 2rem; }

.node-type-fellowship .paragraphs-items-field-fellowship-additiona {
  margin-top: 2rem; }

.node-type-research-lab .field-lab-pi .field-label {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: #666666;
  font-size: 1.2rem;
  letter-spacing: .2rem;
  font-weight: 700; }

.node-type-research-day .node-research-day {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .node-type-research-day .node-research-day:before, .node-type-research-day .node-research-day:after {
    content: " ";
    display: table; }
  .node-type-research-day .node-research-day:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .node-type-research-day .node-research-day {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .node-type-research-day .node-research-day {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .node-type-research-day .node-research-day {
      width: 1170px; } }

.view-person-list-directory-override .views-field-view .view-content {
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .view-person-list-directory-override .views-field-view .view-content .views-field-nothing .field-content {
    display: inline-block;
    color: #fff;
    background-color: #cf4520;
    border-radius: .75rem;
    margin-top: 1rem;
    padding: .5rem;
    width: 80%;
    text-align: center; }
    .view-person-list-directory-override .views-field-view .view-content .views-field-nothing .field-content a {
      color: inherit; }

.view-research-labs-list .view-content {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap; }
  .view-research-labs-list .view-content:before, .view-research-labs-list .view-content:after {
    content: " ";
    display: table; }
  .view-research-labs-list .view-content:after {
    clear: both; }
  .view-research-labs-list .view-content .views-row {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    margin-top: 2rem; }
    @media (min-width: 992px) {
      .view-research-labs-list .view-content .views-row {
        float: left;
        width: 50%; } }
    .view-research-labs-list .view-content .views-row .views-field {
      display: inline-block; }
    .view-research-labs-list .view-content .views-row .views-field-field-wcm-person-image {
      width: 34%;
      float: left;
      border-top: 5px solid #cf4520;
      background-color: #f7f7f7; }
    .view-research-labs-list .view-content .views-row .text-group {
      background-color: #f7f7f7;
      width: 66%;
      float: left;
      display: flex;
      flex-grow: 1;
      padding: 3rem 2rem 0rem 3rem;
      padding-bottom: 0; }
      @media screen and (min-width: 768px) {
        .view-research-labs-list .view-content .views-row .text-group {
          padding: 2rem 2rem 0rem 2rem; } }
      .view-research-labs-list .view-content .views-row .text-group .field-content {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .view-research-labs-list .view-content .views-row .text-group .field-content h3 {
          margin-top: 0; }
          .view-research-labs-list .view-content .views-row .text-group .field-content h3 a {
            text-decoration: underline; }
            .view-research-labs-list .view-content .views-row .text-group .field-content h3 a::after {
              content: ''; }

.view-books-by-author:not(.view-display-id-panel_pane_2) .view-filters {
  margin-bottom: 2rem; }

.view-books-by-author:not(.view-display-id-panel_pane_2) .view-content {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap; }
  .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content:before, .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content:after {
    content: " ";
    display: table; }
  .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content:after {
    clear: both; }
  .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    margin-top: 2rem; }
    @media (min-width: 992px) {
      .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row {
        float: left;
        width: 33.33333%; } }
    .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-cover {
      border-top: 5px solid #cf4520;
      background-color: #f7f7f7; }
      .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-cover img {
        width: 100%; }
    .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field:not(:last-of-type) {
      margin-bottom: 1rem; }
    .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link {
      margin-top: auto; }
      .view-books-by-author:not(.view-display-id-panel_pane_2) .view-content .views-row .views-field-field-book-link .field-content {
        text-align: center;
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem;
        width: 80%;
        border-radius: 1rem; }

.view-research-lab-team .view-header h2 {
  margin-top: 3rem;
  margin-bottom: 1rem; }

.view-research-lab-team .view-content {
  overflow: hidden;
  width: 100%; }
  .view-research-lab-team .view-content .views-row {
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 992px) {
      .view-research-lab-team .view-content .views-row {
        float: left;
        width: 50%; } }
    @media screen and (min-width: 1200px) {
      .view-research-lab-team .view-content .views-row {
        width: 33%; } }

.view-careers-list .views-row,
.view-fellowship-list .views-row,
.view-resident-research-day-list .views-row {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 3rem; }

.view-biblio-views-lab {
  /* =Publications Index
    ----------------------------------------------------------*/ }
  .view-biblio-views-lab .views-row {
    margin: 0 0 10px; }
    .view-biblio-views-lab .views-row [class^="biblio-title"] {
      font-weight: bold; }
  .view-biblio-views-lab h3 {
    color: #000000;
    font-weight: bold;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    padding: 10px 15px 5px;
    margin: 20px 0; }

.view-related-faculty .view-content .person-list-item {
  margin-bottom: 0;
  margin-top: 2rem; }

.view-divisions-list .views-row-last {
  margin-bottom: 4rem; }

.view-divisions-list .views-row {
  background: #f7f7f7;
  padding: 2rem 4rem 4rem;
  margin-top: 2rem; }
  .view-divisions-list .views-row:hover .views-field-field-featured-image img {
    transform: scale(1.2); }
  .view-divisions-list .views-row:hover .views-field-title h3:after {
    border-bottom: 2px solid #b31b1b; }
  .view-divisions-list .views-row .views-field-title {
    text-align: center;
    margin-bottom: 2rem; }
    .view-divisions-list .views-row .views-field-title h3:after {
      content: '';
      border-bottom: 2px solid #dddddd;
      position: relative;
      width: 25%;
      display: block;
      margin: 0 auto;
      padding-top: 1rem;
      transition: all 0.3s ease 0s; }
  .view-divisions-list .views-row .views-field-field-featured-image {
    text-align: center;
    padding-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .view-divisions-list .views-row .views-field-field-featured-image {
        padding-left: 4rem;
        padding-right: 4rem; } }
    .view-divisions-list .views-row .views-field-field-featured-image img {
      transition: all 0.3s ease 0s; }
    .view-divisions-list .views-row .views-field-field-featured-image .field-content {
      overflow: hidden; }
  @media screen and (min-width: 768px) {
    .view-divisions-list .views-row .views-field-body {
      margin-left: 4rem;
      margin-right: 4rem; } }

.view-specialties-by-division .views-row-last {
  margin-bottom: 4rem; }

.view-specialties-by-division .views-row {
  background: #f7f7f7;
  padding: 2rem 4rem 4rem;
  margin-top: 2rem; }
  .view-specialties-by-division .views-row:hover .views-field-field-featured-image img {
    transform: scale(1.2); }
  .view-specialties-by-division .views-row:hover .views-field-title h3:after {
    border-bottom: 2px solid #b31b1b; }
  .view-specialties-by-division .views-row .views-field-title {
    text-align: center;
    margin-bottom: 2rem; }
    .view-specialties-by-division .views-row .views-field-title h3:after {
      content: '';
      border-bottom: 2px solid #dddddd;
      position: relative;
      width: 25%;
      display: block;
      margin: 0 auto;
      padding-top: 1rem;
      transition: all 0.3s ease 0s; }
  .view-specialties-by-division .views-row .views-field-field-featured-image {
    text-align: center;
    padding-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .view-specialties-by-division .views-row .views-field-field-featured-image {
        padding-left: 4rem;
        padding-right: 4rem; } }
    .view-specialties-by-division .views-row .views-field-field-featured-image img {
      transition: all 0.3s ease 0s; }
    .view-specialties-by-division .views-row .views-field-field-featured-image .field-content {
      overflow: hidden; }
  @media screen and (min-width: 768px) {
    .view-specialties-by-division .views-row .views-field-body {
      margin-left: 4rem;
      margin-right: 4rem; } }

.view-news-posts .view-content .views-row {
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #dddddd; }
  .view-news-posts .view-content .views-row .views-field-field-featured-image img {
    width: 100%; }
  @media screen and (min-width: 768px) {
    .view-news-posts .view-content .views-row .views-field-field-featured-image {
      margin-left: 2rem;
      margin-top: 2rem;
      float: right; } }
  .view-news-posts .view-content .views-row .views-field-created {
    text-transform: uppercase;
    margin-bottom: 2rem; }
  .view-news-posts .view-content .views-row .views-field-body {
    overflow: hidden; }

/* IMPORTANT!
This file should only contain code that styles custom panel panes
Ensure they are "self-contained" in either a row, or it's own container
for nesting purposes.
*/
.pane-bundle-link-button-callout {
  position: relative; }
  .pane-bundle-link-button-callout .field-link-buttons {
    margin-left: -20px;
    margin-right: -20px; }
    .pane-bundle-link-button-callout .field-link-buttons:before, .pane-bundle-link-button-callout .field-link-buttons:after {
      content: " ";
      display: table; }
    .pane-bundle-link-button-callout .field-link-buttons:after {
      clear: both; }
    @media screen and (min-width: 992px) {
      .pane-bundle-link-button-callout .field-link-buttons {
        display: flex; } }
    .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:not(:first-child) {
        margin-top: 2rem; }
        @media screen and (min-width: 992px) {
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:not(:first-child) {
            margin-top: initial; } }
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(2):first-child,
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(2):first-child ~ .entity-paragraphs-item {
        position: relative;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (min-width: 992px) {
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(2):first-child,
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(2):first-child ~ .entity-paragraphs-item {
            float: left;
            width: 50%; } }
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(3):first-child,
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(3):first-child ~ .entity-paragraphs-item {
        position: relative;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (min-width: 992px) {
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(3):first-child,
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(3):first-child ~ .entity-paragraphs-item {
            float: left;
            width: 33.33333%; } }
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(4):first-child,
      .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(4):first-child ~ .entity-paragraphs-item {
        position: relative;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (min-width: 992px) {
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(4):first-child,
          .pane-bundle-link-button-callout .field-link-buttons .entity-paragraphs-item:nth-last-child(4):first-child ~ .entity-paragraphs-item {
            float: left;
            width: 25%; } }

.pane-bundle-multiple-contact-callout .fieldable-panels-pane {
  padding: 3rem 4rem;
  background-color: #f7f7f7; }
  .pane-bundle-multiple-contact-callout .fieldable-panels-pane h2 {
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    background-color: #fff;
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      .pane-bundle-multiple-contact-callout .fieldable-panels-pane h2 {
        float: left;
        width: 100%; } }
  .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person {
    margin-left: -20px;
    margin-right: -20px;
    background-color: #fff;
    padding: 1rem; }
    .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person:before, .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person:after {
      content: " ";
      display: table; }
    .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person:after {
      clear: both; }
    .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person > article {
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person > article {
          float: left;
          width: 33.33333%; } }
      .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person > article .field-wcm-person-first-name,
      .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person > article .field-wcm-person-last-name {
        font-weight: bold; }
      .pane-bundle-multiple-contact-callout .fieldable-panels-pane .field-contact-person > article .field-label {
        display: inline-block; }

.pane-bundle-three-text-panes .pane-title {
  text-align: center; }

.pane-bundle-three-text-panes .field-text-cols {
  margin-left: -20px;
  margin-right: -20px; }
  .pane-bundle-three-text-panes .field-text-cols:before, .pane-bundle-three-text-panes .field-text-cols:after {
    content: " ";
    display: table; }
  .pane-bundle-three-text-panes .field-text-cols:after {
    clear: both; }
  .pane-bundle-three-text-panes .field-text-cols .text-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(2):first-child,
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(2):first-child ~ .text-col {
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(2):first-child,
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(2):first-child ~ .text-col {
          float: left;
          width: 50%; } }
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(3):first-child,
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(3):first-child ~ .text-col {
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(3):first-child,
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(3):first-child ~ .text-col {
          float: left;
          width: 33.33333%; } }
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(4):first-child,
    .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(4):first-child ~ .text-col {
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(4):first-child,
        .pane-bundle-three-text-panes .field-text-cols .text-col:nth-last-child(4):first-child ~ .text-col {
          float: left;
          width: 25%; } }

@media screen and (min-width: 992px) {
  .pane-bundle-quick-links.ul-inline {
    padding-left: 8rem;
    padding-right: 8rem; } }

.panel-pane {
  /* 
    * Give all panels a top margin, except node-title and node-body
    */ }
  .panel-pane:not(.pane-node-title):not(.pane-node-body) {
    margin-top: 2rem; }
    .panel-pane:not(.pane-node-title):not(.pane-node-body).pane-upcoming-events {
      margin-top: 0; }

/*
 * The file is intended to hold layout information only. Note the &:not(.panels-ipe-editing) class is used for the layout
 * everywhere except the administrative layout interface. For this, the &.logged-in and .panels-ipe-editing allows special
 * layout for the interface, which most users should not see. That may need adjusting in the event a page layout is updated.
 */
/*
 * The file is intended to hold layout information only. Note the &:not(.panels-ipe-editing) class is used for the layout
 * everywhere except the administrative layout interface. For this, the &.logged-in and .panels-ipe-editing allows special
 * layout for the interface, which most users should not see. That may need adjusting in the event a page layout is updated.
 */
/*
 * The file is intended to hold layout information only. Note the &:not(.panels-ipe-editing) class is used for the layout
 * everywhere except the administrative layout interface. For this, the &.logged-in and .panels-ipe-editing allows special
 * layout for the interface, which most users should not see. That may need adjusting in the event a page layout is updated.
 */
/*
 * The file is intended to hold layout information only. Note the &:not(.panels-ipe-editing) class is used for the layout
 * everywhere except the administrative layout interface. For this, the &.logged-in and .panels-ipe-editing allows special
 * layout for the interface, which most users should not see. That may need adjusting in the event a page layout is updated.
 */
.page-node-39 .pane-bundle-text {
  text-align: center; }

.alpha-pager {
  padding-left: 0; }
  .alpha-pager .alpha-wrap {
    display: inline-block;
    padding-right: .75rem;
    padding-left: .25rem;
    border-right: 1px solid #dddddd; }

.view-person-list-directory-override .view-content h3 {
  margin-bottom: 1rem;
  width: 100%;
  padding-left: 2rem; }

/* IMPORTANT!
This file should only contain self-contained STYLE code, not GRID LAYOUT.
Please do not use Bootstrap inside paragraphs! If you must, then keep them self
contained inside of a container-fluid element.
Only do grid layout in _pages or _panels, or on fieldsets and fieldable_panel_panes
when necessary if possible.
*/
/* IMPORTANT!
This file should only contain self-contained STYLE code, not GRID LAYOUT.
Please do not use Bootstrap inside paragraphs! If you must, then keep them self 
contained inside of a container-fluid element. 
Only do grid layout in _pages or _panels, or on fieldsets and fieldable_panel_panes
when necessary if possible.
*/
.paragraphs-item-callout-link-fields {
  display: flex;
  flex-direction: column; }
  .paragraphs-item-callout-link-fields a {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: none; }
    .paragraphs-item-callout-link-fields a .content {
      display: inline-flex;
      align-items: center; }
      .paragraphs-item-callout-link-fields a .content .field-title:after {
        content: '';
        display: block;
        transition: all 0.2s ease 0s;
        border-bottom: 1px solid #fff;
        width: 0;
        margin-left: 50%; }
    .paragraphs-item-callout-link-fields a:hover .field-title:after {
      width: 110%;
      margin-left: -5%; }
  .paragraphs-item-callout-link-fields .content {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 3rem;
    position: relative;
    border: 1px solid #dddddd;
    width: 100%;
    padding-right: 5.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center; }
    .paragraphs-item-callout-link-fields .content h3 {
      margin-top: 0; }
    .paragraphs-item-callout-link-fields .content i {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: 1px solid #dddddd;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #ffc72c;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-right: .1rem; }
  .paragraphs-item-callout-link-fields .external-link::after {
    content: none; }

.paragraphs-items-field-lab-grants .paragraphs-item-grant-fields {
  border-bottom: 1px solid #dddddd; }

footer.page-footer nav.footer-nav {
  padding-bottom: 1rem; }
  footer.page-footer nav.footer-nav .content {
    column-count: 3;
    column-gap: 2rem; }
