.node-type-fellowship{

    .field-deadline-information{
        border: 2rem solid $wcm-bg-gray;
        padding: 1rem 2rem;
    }

    .paragraphs-items-field-fellowship-additiona{
        margin-top: 2rem;
    }

    .field-fellowship-application-cal{
        @extend .wcm-dark-orange-bg;
    }


}