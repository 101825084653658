// Link Button Callout
.pane-bundle-link-button-callout{
    position: relative;

    .field-link-buttons{
        @include make-row();
        @include breakpoint($md) {
            display: flex;
        }

        .entity-paragraphs-item{
            &:not(:first-child){
                margin-top: 2rem;
                @include breakpoint($md) {
                    margin-top: initial;
                }
            }

            // Make all 12 columns at mobile
            @include make-xs-column(12);
            // If 2 children, make them 6 cols
            &:nth-last-child(2):first-child, 
            &:nth-last-child(2):first-child ~ .entity-paragraphs-item {
                @include make-md-column(6);
            }
            // If 3 children, make them 4 cols
            &:nth-last-child(3):first-child, 
            &:nth-last-child(3):first-child ~ .entity-paragraphs-item {
                @include make-md-column(4);
            }
            // If 4 children, make them 3 cols
            &:nth-last-child(4):first-child, 
            &:nth-last-child(4):first-child ~ .entity-paragraphs-item {
                @include make-md-column(3);
            }

        }

    }
}