/* IMPORTANT!
This file should only contain self-contained STYLE code, not GRID LAYOUT.
Please do not use Bootstrap inside paragraphs! If you must, then keep them self 
contained inside of a container-fluid element. 
Only do grid layout in _pages or _panels, or on fieldsets and fieldable_panel_panes
when necessary if possible.
*/


// Link Button Callouts
.paragraphs-item-callout-link-fields{
    display: flex;
    flex-direction: column;

    @extend .wcm-dark-orange-bg;

    a{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-bottom: none;

        .content{
            display: inline-flex;
            align-items: center;

            .field-title{
                &:after {
                    content: '';
                    display: block;
                    transition: all 0.2s ease 0s;
                    border-bottom: 1px solid $wcm-white;
                    width: 0;
                    margin-left: 50%;
                }
            }
        }

        &:hover{
            .field-title{
                &:after {
                    width: 110%;
                    margin-left: -5%;
                }
            }
        }
    }

    .content{
        @include callout-text-h();
        position: relative;
        border: 1px solid $wcm-border-gray;
        width: 100%;
        padding-right: 5.5rem;

        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;

        i{
            @include callout-text-arrow-h();
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-right: .1rem;
        }

    }
    .external-link{
        &::after{
            content: none;
        }
    }
}