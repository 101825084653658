// Content Page from Panopoly
.node-type-panopoly-page{
    &:not(.panels-ipe-editing){
        .main-content__body{
            @include make-row();

            .panel-pane,
            .ctools-collapsible-container{
                @include make-xs-column(12);

            }
        }
    }
}