.node-contact{
    text-align: center;

    .field-contact-details{
        font-weight: bold;
    }

    .group-phone-details{
        margin-top: 1rem;

        > div{
            display: inline-block;
            
            &:not(:last-child){
                border-right: 2px solid $wcm-border-gray;
                margin-right: 2rem;
                padding-right: 2rem;
            }
        }

        .field-label{
            display: inline;
        }

    }
}

article.node-contact{
    border: 3rem solid $wcm-bg-gray;
    padding: 2rem;
}