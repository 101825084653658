@import "../../../../../profiles/wcmc/modules/custom/wcm_person/css/wcm_person.scss";

.view-related-faculty{
    // Apply the original SCSS class from the wcm_person module
    @extend .view-person-list;
}

.node-type-specialty{
    .pane-node-field-body{
        margin-top: 1rem;
    }
}