/* IMPORTANT!
This file should only contain code that styles custom panel panes
Ensure they are "self-contained" in either a row, or it's own container
for nesting purposes.
*/

@import './panels/link_button_callout';
@import './panels/multiple_contact_callout';
@import './panels/three_text_panes';
@import './panels/quick_links';

.panel-pane{
    /* 
    * Give all panels a top margin, except node-title and node-body
    */
    &:not(.pane-node-title):not(.pane-node-body){
        margin-top: 2rem;
        
        &.pane-upcoming-events{
            margin-top: 0;
        }
    }
}
