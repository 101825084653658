
// Research Labs Landing page
.view-research-labs-list{
    .view-content{
        @include make-row();
        display: flex;
        flex-wrap: wrap;

        .views-row{
            @include make-xs-column(12);
            @include make-md-column(6);
            display: flex;
            margin-top: 2rem;
            
            .views-field{
                display: inline-block;
            }
            .views-field-field-wcm-person-image{
                width: 34%;
                float: left;
                border-top: 5px solid $wcm-dark-orange;
                background-color: $wcm-bg-gray;
            }
            .text-group{
                background-color: $wcm-bg-gray;
                width: 66%;
                float: left;
                display: flex;
                flex-grow: 1;
                padding: 3rem 2rem 0rem 3rem;
                padding-bottom: 0;

                @include breakpoint($sm) {
                    padding: 2rem 2rem 0rem 2rem;
                }

                .field-content{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    h3{
                        margin-top: 0;
                        a{
                            text-decoration: underline;
                            &::after{
                                content: '';
                            }
                        }
                    }    
                }
            }
        }
    }
}