/**
 * Example Stub for creating WCMC Subthemes
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import '../../../../../profiles/wcmc/themes/wcm_brand_base/fonts/fontello/css/fontello.css';

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";


/* =Site-specific styles
----------------------------------------------------------*/

// Fix Select2
@import "./default-select-style.scss";

// Local Mixins
@import './mixins';
@import './utilities';

// Theme Styles
@import "./content_types";
@import "./views";
@import "./panels";
@import "./pages";
@import './paragraphs';
@import './footer';
