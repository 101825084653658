/*
 * This file is for mixins ONLY. Work in the file you need them
 * in, then cut and paste them here when final.
 */

// For panel pane callouts with arrow on right side
@mixin callout-text-h{
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 3rem;

    h3{
        margin-top: 0;
    }
}

// For the Arrow itself (<i>) on callouts
@mixin callout-text-arrow-h{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 1px solid $wcm-border-gray;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: $wcm-yellow;
}

// Background and forground text colors
.wcm-red-bg {
    div,
    h2,
    h3,
    h4,
    a{
        color: $wcm-white;
        background-color: $wcm-red;
    }
}
.wcm-dark-orange-bg {
    div,
    h2,
    h3,
    h4,
    a{
        color: $wcm-white;
        background-color: $wcm-dark-orange;
    }
}

/**
 * Mixin targeting the Biblio-related content
 */
@mixin biblio-views {
    /* =Publications Index
    ----------------------------------------------------------*/
    .views-row {
        margin: 0 0 10px;

        [class^="biblio-title"]{
            font-weight: bold;
        }
    }

    h3 {
        color: #000000;
        font-weight: bold;
        background-color: #e1e1e1;
        border: 1px solid #ccc;
        padding: 10px 15px 5px;
        margin: 20px 0;
    }
}

// Mixin for Divisions and Specialty listing Views
@mixin specialty-callout-view {
      .views-row-last{
        margin-bottom: 4rem;
      }

      .views-row {
        background: $wcm-bg-gray;
        padding: 2rem 4rem 4rem;
        margin-top: 2rem;

        &:hover {
            .views-field-field-featured-image img {
                transform: scale(1.2);
            }
            .views-field-title h3:after {
                border-bottom: 2px solid $wcm-red;
            }
        }
        .views-field-title {
            text-align: center;
            margin-bottom: 2rem;
            h3 {
                &:after {
                    content: '';
                    border-bottom: 2px solid $wcm-border-gray;
                    position: relative;
                    width: 25%;
                    display: block;
                    margin: 0 auto;
                    padding-top: 1rem;
                    transition: all 0.3s ease 0s;
                }
            }
        }

        .views-field-field-featured-image {
            text-align: center;
            @include breakpoint($sm) {
                padding-left: 4rem;
                padding-right: 4rem;
            }
            padding-bottom: 2rem;

            img {
                transition: all 0.3s ease 0s;
            }

            .field-content {
                overflow: hidden;
            }
        }

        .views-field-body{
            @include breakpoint($sm){
                margin-left: 4rem;
                margin-right: 4rem;
            }
        }
    }
}
