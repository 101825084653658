// Three Text Panes Callout
.pane-bundle-three-text-panes{
    .pane-title{
        text-align: center;
    }
    .field-text-cols{
        @include make-row();

        .text-col{
            // Make all 12 columns at mobile
            @include make-xs-column(12);
            // If 2 children, make them 6 cols
            &:nth-last-child(2):first-child, 
            &:nth-last-child(2):first-child ~ .text-col {
                @include make-md-column(6);
            }
            // If 3 children, make them 4 cols
            &:nth-last-child(3):first-child, 
            &:nth-last-child(3):first-child ~ .text-col {
                @include make-md-column(4);
            }
            // If 4 children, make them 3 cols
            &:nth-last-child(4):first-child, 
            &:nth-last-child(4):first-child ~ .text-col {
                @include make-md-column(3);
            }
        }
    }
}