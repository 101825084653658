
// For any panel that wants to show a ul element inline ala Homepage
.ul-inline{
    ul{
        display: flex;
        justify-content:space-evenly;
        flex-wrap: wrap;
        padding: 0;
        li{
            display: flex;
        }
    }
}