// Books page`
.view-books-by-author{

    // Avoid the second Display mode used to generate the "View Books" button
    &:not(.view-display-id-panel_pane_2){
        .view-filters{
            margin-bottom: 2rem;
        }

        .view-content{
            @include make-row();
            display: flex;
            flex-wrap: wrap;
    
            .views-row{
                @include make-xs-column(12);
                @include make-md-column(4);
                display: flex;
                flex-grow: 0;
                flex-direction: column;
                margin-top: 2rem;
    
                .views-field-field-book-cover{
                    border-top: 5px solid $wcm-dark-orange;
                    background-color: $wcm-bg-gray;
    
                    img{
                        width: 100%;
                    }
                }
    
                .views-field{
                    &:not(:last-of-type){
                        margin-bottom: 1rem;
                    }
                }
    
                .views-field-field-book-link{
                    @extend .wcm-dark-orange-bg;
                    margin-top: auto;
                    .field-content{
                        text-align: center;
                        display: inline-block;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                        width: 80%;
                        border-radius: 1rem;
                        
                    }
                }
            }
        }
        
    }
}